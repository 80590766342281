jQuery(document).ready(function ($) {
  $("#product-image-carousel").owlCarousel({
    loop: true,
    margin: 10,
    nav: true,
    dots: true,
    items: 1,
    navText: [
      '<i class="fal fa-angle-left" aria-hidden="true"></i>',
      '<i class="fal fa-angle-right" aria-hidden="true"></i>',
    ],
  });
  $("#related-products-carousel").owlCarousel({
    loop: false,
    margin: 10,
    nav: true,
    navText: [
      '<i class="fal fa-angle-left" aria-hidden="true"></i>',
      '<i class="fal fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  });
  $(".featured-links-carousel").owlCarousel({
    loop: false,
    margin: 10,
    nav: true,
    navText: [
      '<i class="fal fa-angle-left" aria-hidden="true"></i>',
      '<i class="fal fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  });
  $(".featured-images-carousel").owlCarousel({
    loop: false,
    margin: 10,
    nav: true,
    navText: [
      '<i class="fal fa-angle-left" aria-hidden="true"></i>',
      '<i class="fal fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 3,
      },
      // 1000:{
      //     items:3
      // }
    },
  });
});
